import React, { Component, Fragment } from 'react'
import Navbar from '../navbar/navLogin'
import './otp.scss'
import Icon from '../../asset/img/motorbike.jpg'

import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'


export default class Otp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            otp : ""
        }
    }

    getInput = (e) => {
        this.setState({
            otp: e.target.value
        })
    }

    prosesInput = () => {
        let no = this.state.otp
        let ln = no.length

        if (ln === 4) {
            // localStorage.setItem("phone", this.state.phone)
            this.props.history.push('/Page/Home')
            // console.log(no);
            
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Masukan OTP yang sesuai',
                type: 'warning',
                confirmButtonText: 'OK'
            })
        }
    }

    getValid = () => {
        let phone = localStorage.getItem("phone")
        if (!phone) {
            this.props.history.push('/')
        }
    }

    componentDidMount(){
        this.getValid()
        this.setState({
            phone: localStorage.getItem("phone")
        })
    }

    

    render() {
        return (
            <Fragment>
                <Navbar />
                <br />
                <div className="content">
                    <img src={Icon} alt="Logo" width="30%" className="circle responsive-img" />
                    <p>&nbsp;</p>
                    <h5>Masukan OTP yang Dikirim Ke nomor anda</h5>
                    <br />
                    <div className="container">
                        <input type="number" className="logins" placeholder="XXXX" onChange={(e) => this.getInput(e)} value={this.state.otp} />
                        <br />
                        <br />
                        <button className="btn waves-effect waves-light btn-large green darken-2" type="button" onClick={this.prosesInput} >
                            MASUK
                        </button>
                    </div>

                </div>
                <div className="small container">
                    <p className="boxs">
                        message
                    </p>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Fragment>
        )
    }
}