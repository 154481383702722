import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom'

import './nav.scss'


export default class NavBack extends Component {
    render(){
        return (
            <Fragment>
                <nav className="back-fix">
                    <div className="nav-wrapper green">
                        <ul className="left">
                            <li><Link to="/"><i className="material-icons left">arrow_back</i> <b> Kembali</b></Link></li>
                        </ul>
                    </div>
                </nav>
            </Fragment>
        )
    }
}