import React from "react";

import './nav.scss'

const Navbar = () => {
    return (
        <nav className="nav-extended green darken-2 padding">
            <div className="nav-wrapper">
                <span className="brand-logo">Mas Pur</span>
            </div>
            <div className="nav-content">
                Mas Pedagang Sayur
            </div>
        </nav>
    )
}

export default Navbar;