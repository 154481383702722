import React, { Component, Fragment } from 'react';

import './chart.scss'

export default class ChartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Imgsayur: 'http://www.lumina-agro.com/wp-content/uploads/2018/12/sayur.png',
            foto: "https://i.ibb.co/2y96Rqw/kisspng-avatar-user-computer-icons-software-developer-5b327cc9cc15f7-872727801530035401836.png",
            sayur: "https://img4.apk.tools/300/5/8/a/bismillah.kang_sayur.png",
            storage : {},
        }
    }
    
    setStorage = () => {
        
        var obj = Object.keys(sessionStorage).reduce(function (obj, key) {
            obj[key] = JSON.parse(sessionStorage.getItem(key));
            return obj
        }, {});
        this.setState({
            storage: obj
        })
    }

    addDays = (days) => {
        return new Date(Date.now() + 864e5 * days);
    }

    setDate = (day = 1) => {
        let tgl = this.addDays(day)
        localStorage.setItem('tgl', tgl.getDate())
        localStorage.setItem('bln', tgl.getMonth() + 1 )
        localStorage.setItem('thn', tgl.getFullYear() )
    }

    cardTgl = () =>{
        return (
            <div className="card modal-card">
                    <div className="contents">
                        Pilih Tanggal :
                        <button className="right btn-small btn-floating btn-flat red lighten-4 red-text waves-effect waves-red" ><b>X</b></button>
                        <hr className="line" />
                        <div className="row">
                            <div className="col s4">
                                <button className="btn btn-flat grey lighten-2 green-text waves-effect waves-green" >{localStorage.getItem('tgl')}/{localStorage.getItem('bln')}/{localStorage.getItem('thn')}</button>
                            </div>
                            <div className="col s4">
                                <button className="btn btn-flat grey lighten-2 green-text waves-effect waves-green" >{parseInt(localStorage.getItem('tgl')) + 1}/{localStorage.getItem('bln')}/{localStorage.getItem('thn')}</button>
                            </div>
                            <div className="col s4">
                                <button className="btn btn-flat grey lighten-2 green-text waves-effect waves-green" >{parseInt(localStorage.getItem('tgl')) + 2}/{localStorage.getItem('bln')}/{localStorage.getItem('thn')}</button>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }

    componentDidMount(){
        this.setStorage()
        this.setDate()        
    }

    render(){
        return (
            <Fragment>
                <div className="padding green">
                    <button className="btn-floating btn-small btn-flat white right" onClick={() => this.props.history.goBack()}>
                        <i className="material-icons red-text">close</i>
                    </button>
                    <h2 className="white-text"> Checkout Page</h2>
                </div>
                

                <div className="pad-content">

                    <ul className="collection">
                        <li className="collection-item avatar lime lighten-3">
                        <img src={this.state.sayur} alt="" className="circle" />
                        <span className="title">Pedagang Sayur</span>
                        <p>
                            Komplek Serang Barat <br />
                            
                        </p>
                        </li>
                    </ul>

                    <div className="card">
                        <ul className="collection">
                            {Object.keys(this.state.storage).map((item, i) => (
                                <li className="collection-item avatar" key={i}>
                                    <img src={this.state.Imgsayur} alt="" className="circle" />
                                    <span className="title"><b>{item}</b></span>
                                    <p className="desk"> 
                                        Qty : {sessionStorage.getItem(item)} || Rp. 12.000 <br />
                                        Deskripsi
                                    </p>
                                        <button className="secondary-content right btn-floating btn-flat"><i className="material-icons green-text">edit</i></button>
                                </li>
                            ))}
                        </ul>
                        <hr className="line" />
                        <p className="padding">
                            Perkiraan Total Harga <span className="right red-text">Rp. 124.000</span> <br/>
                            Ongkos Kirim <span className="right blue-text">Rp. 2.000</span> <br/>
                            Total Perkiraan Bayar <span className="right blue-text">Rp. 126.000</span> <br/>
                        </p>
                        <hr className="line" />
                        <div className="padding">
                            Bayar Menggunakan : 
                            <div className="padding row">
                                {/* <form> */}
                                    <div className="col s4"> 
                                        <label>
                                            <input name="group1" type="radio" value="tunai" defaultChecked onClick={(e)=>console.log(e.target.value)} />
                                            <span>Tunai Sebesar Rp.</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        <label>
                                            <input type="text" defaultValue="150.000"/>
                                        </label>
                                    </div>
                                    <div className="col s12">
                                    <p className="desk">
                                        Tuliskan jumlah uang yg disiapkan agar MasPur bisa menyiapkan Kembalian
                                    </p>
                                    <p>&nbsp;</p>
                                    </div>
                                    <div className="col s4"> 
                                        <label>
                                            <input name="group1" type="radio" value="tunai" onClick={(e)=>console.log(e.target.value)} disabled />
                                            <span>Deposit Saldo Rp. (disabled) </span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        <label>
                                            <input type="text" disabled placeholder="(disabled)" />
                                        </label>
                                    </div>
                                    <div className="col s12">
                                        <p className="desk">
                                            Anda juga bisa melakukan pembayaran menggunakan deposit Saat pesanan sudah dikirim MasPur
                                        </p>
                                    </div>

                                
                                    
                                {/* </form> */}
                            </div>
                        </div>
                        <hr className="line" />
                            <div className="padding row">
                                Pilih Waktu Antar :
                                <p>&nbsp;</p>
                                <input type="date" className="col s6"/>
                                <input type="date" className="col s6"/>


                            </div>
                            <hr/>
                    </div>

                    {/* Card Tgl */}
                    {/* <this.cardTgl></this.cardTgl>  */}
                </div>
            </Fragment>
        )
    }
}