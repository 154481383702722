import React, {Component, Fragment} from "react";

import { Link } from 'react-router-dom'

import './nav.scss'

export default class Navbar extends Component {


    render() {
        return (
            <Fragment>
                <nav className="nav-extended green darken-2 padding-fixed">
                    <div className="nav-wrapper">
                        <span className="brand-logo left">Mas Pur</span>
                    </div>
                    <div className="nav-content">
                        Mas Pedagang Sayur
                        <ul className="right">
                            <li className="no-padd">
                                <Link to="/Page/History" className="btn-floating waves-effect waves-light lime accent-4"><i className="material-icons">history</i></Link>
                            </li>
                            {/* <li className="no-padd">
                                <Link to="/Page/Akun" className="btn-floating waves-effect waves-light blue darken-4"><i className="material-icons">person_outline</i></Link>
                            </li> */}
                            <li className="no-padd">
                                <Link to="/Page/Chart" className="btn-floating waves-effect waves-light red darken-4"><i className="material-icons">shopping_cart</i></Link>
                            </li>

                        </ul>
                    </div>
                </nav>
            </Fragment>
        )
    }
}