import React, { Component, Fragment } from 'react';

import Navbar from "../../navbar/nav"

import { Link } from "react-router-dom";

import "./home.scss"

export default class PageHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            foto: "https://i.ibb.co/2y96Rqw/kisspng-avatar-user-computer-icons-software-developer-5b327cc9cc15f7-872727801530035401836.png",
            sayur: "https://img4.apk.tools/300/5/8/a/bismillah.kang_sayur.png"
        }
    }
    

    getValid = () => {
        let phone = localStorage.getItem("phone")
        if (!phone) {
            this.props.history.push('/')
        }
    }

    componentDidMount() {
        this.getValid()
    }

    rediirLink = (to) => {
        this.props.history.push(to)
    }

    render(){
        return (
            <Fragment>
                <div className="">
                    <Navbar className="padd-fix" />
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <br />

                
                <div className="content-home">
                    <div className="round-img">
                        <img className="imgs" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBHuShWCGasrahk4_Xuffs7bolSBOBqn3lF-iboslgAdN0fYYs" alt="" /> 
                        <Link to="/Page/Promo" className="right">Klik Promo Lainnya >> </Link>  
                    </div>

                    <p>&nbsp;</p>

                    <div className="">
                        <div className="row">
                            <div className="col s6" onClick={() => this.rediirLink('/Page/Akun')} >
                                <div className="padding">
                                <div className="row card round">
                                    <div className="col s4">
                                        <img src={this.state.foto} alt="" className="responsive-img" />
                                    </div>
                                    <div className="col s8">
                                        <b>Nama User</b>
                                        <div className="saldo">
                                            Deposit : <br/> Rp 200.000
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col s6" onClick={() => this.rediirLink('/Page/Akun')}>
                                <div className="padding">
                                <div className="row card round">
                                    <div className="col s4">
                                        <img src={this.state.sayur} alt="" className="responsive-img" />
                                    </div>
                                    <div className="col s8">
                                        <b>Pedagang</b>
                                        <div className="saldo">
                                            komplek Serang Barat
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    
                    <div className="collection with-header">
                        <div className="collection-header"><h5>Yuk Belanja ..</h5></div>
                        <Link to="/Page/Produk/All" className="collection-item">Semua Produk</Link>
                        <Link to="/Page/Produk/Sayur" className="collection-item">Sayuran</Link>
                        <Link to="/Page/Produk/Buah" className="collection-item">Buah-Buahan</Link>
                        <Link to="/Page/Produk/Bumbu" className="collection-item">Bumbu Dapur</Link>
                    </div>
                </div>
            </Fragment>
        )
    }
}