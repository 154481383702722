import React, { Component, Fragment } from 'react';

import NavBack from '../../navbar/navBack'

import "./akun.scss"

export default class PageAkun extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nama: "Mamat S",
            phone: "0895",
            prov: "Banten",
            kota: "Tanggerang",
            alamat: "Tanggerang",
            blok: "B",
            no_rumah: "11",

            // foto
            foto: "https://i.ibb.co/2y96Rqw/kisspng-avatar-user-computer-icons-software-developer-5b327cc9cc15f7-872727801530035401836.png"
        }
    }

    changeValName = (e) => {
        this.setState({
            nama: e.target.value
        })
    }

    changeValHp = (e) => {
        this.setState({
            phone: e.target.value
        })
    }

    changeValProv = (e) => {
        this.setState({
            prov: e.target.value
        })
    }

    changeValKota = (e) => {
        this.setState({
            kota: e.target.value
        })
    }
    changeValAlamat = (e) => {
        this.setState({
            alamat: e.target.value
        })
    }
    changeValBlok = (e) => {
        this.setState({
            blok: e.target.value
        })
    }
    changeValNoRumah = (e) => {
        this.setState({
            no_rumah: e.target.value
        })
    }




    getValid = () => {
        let phone = localStorage.getItem("phone")
        if (!phone) {
            this.props.history.push('/')
        }
    }

    componentDidMount() {
        this.getValid()
    }


    render() {
        return (
            <Fragment>
                <NavBack />

                <p>&nbsp;</p>
                <div className="content-akun row w3-animate-bottom">

                    <div className="col s12 card round">
                        <div className="center">
                            <img className="imgs " src={this.state.foto} alt="" />
                        </div>
                        <br />
                        <div className="row">
                            <div className="input-field col s12">
                                <span className="helper-text">Nama Lengkap</span>
                                <input id="nama" type="text" className="validate" value={this.state.nama} onChange={(e) => this.changeValName(e)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <span className="helper-text">Nomor HP</span>
                                <input id="HP" type="number" className="validate" value={this.state.phone} onChange={(e) => this.changeValHp(e)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s6">
                                <span className="helper-text">Provinsi</span>
                                <input id="prov" type="text" className="validate" value={this.state.prov} onChange={(e) => this.changeValProv(e)} />
                            </div>
                            <div className="input-field col s6">
                                <span className="helper-text">Provinsi</span>
                                <input id="kota" type="text" className="validate" value={this.state.kota} onChange={(e) => this.changeValKota(e)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <span className="helper-text">Alamat Rumah</span>
                                <input id="alamat" type="text" className="validate" value={this.state.alamat} onChange={(e) => this.changeValAlamat(e)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s6">
                                <span className="helper-text">Blok</span>
                                <input id="blok" type="text" className="validate" value={this.state.blok} onChange={(e) => this.changeValBlok(e)} />
                            </div>
                            <div className="input-field col s6">
                                <span className="helper-text">No Rumah</span>
                                <input id="normh" type="text" className="validate" value={this.state.no_rumah} onChange={(e) => this.changeValNoRumah(e)} />
                            </div>
                        </div>

                    </div>
                    <p>&nbsp;</p>
                </div>
                <div className="foot-fix">
                    <button className="btn btn-large green">Save</button>
                </div>
            </Fragment>
        )
    }
}