import React, { Component, Fragment } from 'react';

import NavBack from '../../navbar/navBack'

import { Link } from "react-router-dom";

import "./history.scss"

export default class PageHistory extends Component {

    getValid = () => {
        let phone = localStorage.getItem("phone")
        if (!phone) {
            this.props.history.push('/')
        }
    }

    componentDidMount() {
        this.getValid()
    }


    render() {
        return (
            <Fragment>
                <NavBack />
                <p>&nbsp;</p>
                <div className="content-history w3-animate-bottom">
                    <br/>
                    <h5>Riwayat pesanan</h5>
                    
                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">local_grocery_store</i></div>
                        <div className="col s9">
                            <b>Pembelian</b> <br/>
                            Total Pembelian Rp 19.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>
                    
                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">local_grocery_store</i></div>
                        <div className="col s9">
                            <b>Pembelian</b> <br/>
                            Total Pembelian Rp 19.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>
                    
                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">local_grocery_store</i></div>
                        <div className="col s9">
                            <b>Pembelian</b> <br/>
                            Total Pembelian Rp 19.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>
                    
                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">local_grocery_store</i></div>
                        <div className="col s9">
                            <b>Pembelian</b> <br/>
                            Total Pembelian Rp 19.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>
                    
                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">local_grocery_store</i></div>
                        <div className="col s9">
                            <b>Pembelian</b> <br/>
                            Total Pembelian Rp 19.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>
                    <Link to="/Page/Promo" className="right">Pesanan Lainnya >> </Link>
                    <p>&nbsp;</p>
                    <hr/>
                    <h5>Riwayat Top-Up</h5>

                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">account_balance_wallet</i></div>
                        <div className="col s9">
                            <b>Sukses</b> <br />
                            Penambahan Deposit Sebesar Rp 100.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>
                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">account_balance_wallet</i></div>
                        <div className="col s9">
                            <b>Sukses</b> <br />
                            Penambahan Deposit Sebesar Rp 100.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>
                    <div className="card row round">
                        <div className="col s3"><i className="material-icons medium">account_balance_wallet</i></div>
                        <div className="col s9">
                            <b>Sukses</b> <br />
                            Penambahan Deposit Sebesar Rp 100.000
                            <div className="beli">
                                12/08/1991
                            </div>
                        </div>
                    </div>

                    <Link to="/Page/Promo" className="right">Top-Up Lainnya >> </Link>
                    <br />
                </div>
            </Fragment>
        )
    }
}