import React, {Fragment} from 'react';
import './App.css';

import Navigate from './component/navigation'

function getWidth() {
  return document.body.offsetWidth
}

function App() {
  let width = getWidth()
  return (
    <Fragment>
      {
        width >= 640 ? <div className="bg-img"></div> : <div className="bg-color"></div>
      }
      <div className="bg">
        <div className="mobile">
          {/* layout Mobile */}
          <Navigate />
        </div>
      </div>
    </Fragment>
  );
}

export default App;
