import React, { Component, Fragment } from 'react'
import Navbar from '../navbar/navLogin'
import './login.scss'
import Icon from '../../asset/img/motorbike.jpg'

import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'


export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone : ""
        }
    }
    
    getInput = (e) => {
        this.setState({
            phone: e.target.value
        })
    }

    prosesInput = () =>{
        let no = this.state.phone
        let ln = no.length

        if (ln >= 10 && ln <= 13) {
            localStorage.setItem("phone", this.state.phone)
            this.props.history.push('/OTP')
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Masukan Nomor HP yang sesuai',
                type: 'warning',
                confirmButtonText: 'OK'
            })
        }
        
        
    }
    getValid = () => {
        let phone = localStorage.getItem("phone")
        if (phone) {
            this.props.history.push('/Page/Home')
        }
    }

    componentDidMount() {
        this.getValid()
    }


    render(){
        return (
            <Fragment>
                <Navbar />
                <br/>
                <div className="content">
                    <img src={Icon} alt="Logo" width="30%" className="circle responsive-img"/>
                    <p>&nbsp;</p>
                    <h5>Masukan Nomor HP anda</h5>
                    <br/>
                    <div className="container">
                        <input type="number" className="logins" placeholder="XXXX" onChange={(e) => this.getInput(e)} value={this.state.phone} /> 
                        <br/>
                        <br/>
                        <button className="btn waves-effect waves-light btn-large green darken-2" type="button" onClick={this.prosesInput} >
                            MASUK
                        </button>
                    </div>
                    
                </div>
                <div className="small container">
                    <p className="boxs">
                        Dengan mendaftar anda menyetujui segala ketentuan yang berlaku, Kami SMS menggunakan server sendiri, kami tidak akan membagikan data nomor HP anda kepada pihak lain. <br />
                        Nomor Hp digunakan untuk berkomunikasi
                    </p>
                </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
            </Fragment>
        )
    }
}