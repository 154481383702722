import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

// style
import './produk.scss'

export default class Produk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: "",
            kategori: this.props.match.params.kategori,
            cardNav : false,
            pilihanSayur : "",
            qtySayur : 1,
            btnShop : true
        }
    }

    changeSrc = (e) => {
        this.setState({
            src: e.target.value
        })
    }

    clearSrc = () => {
        this.setState({
            src: "",
            btnShop : true
        })
    }

    onAdd = () => {
        this.setState(
            {
                qtySayur: parseInt(this.state.qtySayur) + 1
            }
        )    
    }
    
    onMin = () => {
        if (this.state.qtySayur > 1) {
            this.setState(
                {
                    qtySayur : this.state.qtySayur - 1
                }
            )
        }else{
            alert("tidak bisa di kurang lagi")
        }
    }

    saveQty = () => {
        sessionStorage.setItem(this.state.pilihanSayur, this.state.qtySayur)
        this.setState({
            cardNav: false
        })
    }

    Keranjang = () => {
        return (
            <div className="waves-effect btn-floating btn-large red darken-4 btn-keranjang green-text row" onClick={() => this.props.history.push('/Page/Chart')}>
                <div className="col s6">
                    <i className="material-icons">shopping_cart</i>
                </div>                
                <div className="col s6">
                    <div className="badge">
                        <div className="titles">
                            {sessionStorage.length}
                        </div>
                    </div>
                </div>                
            </div>
        )
    }

    navCard = () => {
        return (
            <div className="w3-animate-bottom row" id="btm-card">
                <div className="col s12 m12">

                    <div className="card  gray card-radius">
                    <button className="right btn-floating btn-flat" onClick={()=>this.closeModal()}>
                        <i className="material-icons  red-text">cancel</i>
                    </button>
                    <br/>
                    <div className="card-content green-text">
                        {/* content data */}
                        <div className="row">
                            <div className="col s6 m6 text-title">
                                {this.state.pilihanSayur}
                            </div>
                            <div className="col s6 m6">
                                <div className="right row">
                                    <div className="col s2">
                                        <button className="waves-effect waves-light btn-floating white" onClick={()=>this.onAdd()}>
                                            <i className="material-icons  green-text">add</i>
                                        </button>
                                    </div>
                                    <div className="col s6">
                                        <input className="box-input" type="text" value={this.state.qtySayur} readOnly/>
                                    </div>
                                    <div className="col s2">
                                            <button className="waves-effect waves-light btn-floating white" onClick={() => this.onMin()}>
                                            <i className="material-icons  green-text">remove</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m12">
                                <input type="text" placeholder="Catatan"/>
                            </div>
                        </div>
                    </div>
                    <div className="card-action">
                        <button className="btn green darken-2" onClick={()=>this.saveQty()}>Tambah Ke Kernjang</button>
                    </div>
                </div>
                </div>
            </div>
        )
    }
    
    
    clickModal = (item) => {
        let cek = sessionStorage.getItem(item)
        if (cek === null) {
            this.setState({
                cardNav : true,
                pilihanSayur: item,
                qtySayur : 1
            })
        }else{
            this.setState({
                cardNav : true,
                pilihanSayur: item,
                qtySayur : cek
            })

        }
    }
    
    closeModal = () => {
        this.setState({
            cardNav : false,
        })
    }
    
    setNavbtn = () => {
        this.setState({
            btnShop : false
        })
    }

    componentDidMount(){
    }

    
    render() {
        return (
            <Fragment>
                <nav className="back-fix">
                    <div className="nav-wrapper green darken-2 nav-padding ">
                        <Link to="/Page/Home" className="brand-logo left btn-back"><i className="icns material-icons">arrow_back</i></Link>
                        <div className="input-field right input-ctn">
                            <input id="search" type="search" required value={this.state.src} onChange={(e) => this.changeSrc(e)} onClick={()=>this.setNavbtn()}/>
                            <label className="label-icon" htmlFor="search">
                                <i className="material-icons left">search</i> {this.state.src === "" ? "Search" : ""}</label>
                            <i onClick={() => this.clearSrc()} className="material-icons waves-effect">close</i>
                        </div>
                    </div>
                    {this.state.btnShop ? <this.Keranjang></this.Keranjang> : ""}
                </nav>
                <p>&nbsp;</p> <br/>
                <div className="">
                    <ul className="collection">
                        <li className="collection-item" onClick={()=>this.clickModal("bayam")}>Bayam 
                            <span className="badge green-text">
                                {sessionStorage.getItem('bayam')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("kangkung")}>Kangkung
                            <span className="badge green-text">
                                {sessionStorage.getItem('kangkung')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("terong")}>terong
                            <span className="badge green-text">
                                {sessionStorage.getItem('terong')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("kol")}>kol
                            <span className="badge green-text">
                                {sessionStorage.getItem('kol')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("jagung")}>jagung
                            <span className="badge green-text">
                                {sessionStorage.getItem('jagung')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("brokoli")}>brokoli
                            <span className="badge green-text">
                                {sessionStorage.getItem('brokoli')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("buncis")}>buncis
                            <span className="badge green-text">
                                {sessionStorage.getItem('buncis')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("mentimun")}>mentimun
                            <span className="badge green-text">
                                {sessionStorage.getItem('mentimun')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("bawang")}>bawang
                            <span className="badge green-text">
                                {sessionStorage.getItem('bawang')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("tomat")}>tomat
                            <span className="badge green-text">
                                {sessionStorage.getItem('tomat')}
                            </span>
                        </li>
                        <li className="collection-item" onClick={() => this.clickModal("cabai")}>cabai
                            <span className="badge green-text">
                                {sessionStorage.getItem('cabai')}
                            </span>
                        </li>
                        
                    </ul>
                </div>
                {this.state.cardNav ? <this.navCard></this.navCard> : "" }    
            </Fragment>
        )
    }
}