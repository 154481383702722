import React, { Component, Fragment } from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";


// Router compoent
import Login from './login/index'
import Otp from "./otp/otp";
import PageHome from "./pages/home/home"
import PageHistory from "./pages/history/history"
import PageAkun from "./pages/akun/akun"

import PageChart from './pages/chart'

// Router Produk

// import Sayur from "./pages/produk/sayur/sayur"
import Produk from "./pages/produk"

export default class Navigation extends Component {

    notFound = () => {
        return (
            <div className="container">
                <center>
                    <h2>Page NotFound</h2>
                </center>
            </div>
        )
    }

    render(){
        return (
            <Fragment>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/OTP" component={Otp} />
                        <Route path="/Page/Home" component={PageHome} />
                        <Route path="/Page/History" component={PageHistory} />
                        <Route path="/Page/Akun" component={PageAkun} />
                        <Route path="/Page/Chart" component={PageChart} />

                        {/* Route Produk */}
                        <Route path="/Page/Produk/:kategori" component={Produk} />
                        
                        <Route component={this.notFound} />

                    </Switch>
                </BrowserRouter>
            </Fragment>
        )
    }
}